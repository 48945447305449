<template>
    <div class="cus_user user">
        <!-- 个人信息 -->
        <UserHeader />
        <!-- 功能 -->
        <div class="user_fun">
            <div class="user_fun_view">
                <div
                    v-for="(fun, index) in funList"
                    @click="evGoToView(fun)"
                    class="user_fun_view_item"
                    :key="index"
                >
                    <div>
                        <!-- <span :class="['iconfont', fun.icon]"></span> -->
                        <svg-icon :icon-class="fun.icon" class="iconfont" />
                        <p>{{ fun.title }}</p>
                    </div>
                    <div class="arrows">
                        <span class="iconfont icon-right-jt"></span>
                    </div>
                </div>
            </div>
            <!-- <button class="my_button user_fun_logout" @click="evLogout">退出登录测试 token</button> -->
        </div>
    </div>
</template>

<script>
// 接口
import { GetAgreementList } from '@/api'
// 组件
import UserHeader from './components/user.vue'

export default {
    data () {
        return {
            // 默认头像
            oDefAvatar: require('@/assets/Personal/khjl-tx@2x.png'),
            // 功能列表
            funList: [
                { icon: 'bljd-icon', title: '办理进度', msg: { status: '2,3,4,14' }},
                { icon: 'zdxz-icon', title: '终端选择', msg: { status: '3,13' }},
                { icon: 'dqht-icon', title: '待签合同', msg: { status: '5,15' }},
                { icon: 'yqht-icon', title: '已签合同', msg: { status: '6' }},
                { icon: 'sh-icon', title: '收货', msg: { status: '7,8' }}
            ]
        }
    },
    methods: {
        // 客户订单列表 (接口)
        apiAgreementList: function () {
            const t_Id = localStorage.getItem('thirdId')
            const is_aut = localStorage.getItem('is_sign')
            GetAgreementList({ status: '', 'enterprise_id': t_Id })
                .then(({ code, msg, data }) => {
                    if (code === 1000) {
                        if (data.count === 0) {
                            this.$router.push({
                                'name': 'CusProve',
                                'query': { 'third_id': t_Id, 'code': is_aut === '1' ? '0' : '' }
                            })
                        }
                        return
                    }
                    this.$message.error(msg)
                })
        },
        // 跳转指定页面
        evGoToView: function (item) {
            const { msg } = item
            this.$router.push({
                'name': 'CusPact',
                'query': msg
            })
        },
        // 退出登录 (按钮)
        evLogout: function () {
            const token = localStorage.getItem('token')
            alert('[token]: ' + token)
        }
    },
    mounted () {
        // 调用客户列表订单 判断是否认证
        this.apiAgreementList()
    },
    components: {
        UserHeader
    }
}
</script>

<style lang="scss" scoped>
@import './styles/user.scss';

.user {
    background: url("../../assets/kh-bg@2x.png") no-repeat;
    background-size: 100%;

    &_info {
        &_welcome {
            color: #333;
            font-size: 28px;
            .phone {
				color: #666;
				font-size: 24px;
			}
        }
    }

    &_fun {
        padding: 10px 25px;

        &_view {
            display: block;

            &_item {
                display: flex;
                justify-content: space-between;
                padding: 40px 0 30px;
                width: 100%;
                text-align: left;
                border-bottom: 1px solid #e6e6e6;

                div {
                    display: flex;

                    .iconfont {
                        margin-left: 12px;
                        font-size: 38px;
                        color: #E8941A;
                    }

                    p {
                        margin-left: 30px;
                        line-height: 38px;
                        font-weight: 500;
                        color: #000;
                    }
                }

                .arrows {
                    .iconfont {
                        font-size: 16px;
                        line-height: 38px;
                        color: #B9BBBD;
                    }
                }
            }
        }

        &_logout {
            background: linear-gradient(90deg, #F2970C, #FEC56E);
        }
    }
}
</style>

<style lang="scss">
.el-message-box {
    width: 600px;
}
</style>
